/** @jsx jsx */
// import * as React from "react"
import { Link } from "gatsby"
import { jsx, Link as A } from "theme-ui"
import Layout from "../components/layout"
import Contain from "../components/contain"
import SEO from "../components/seo"
import PageTitle from '../components/pageTitle'
import Headline from '../components/editorial-headline'
import ThicLine from '../components/editorial-thicline'



const NotFoundPage = () => {
  return (
    <Layout>
      {/* Editorial Headline */}
      <Contain mw={"col10"}>
        <SEO title="404" />
        <div sx={{mb:4}}>
          <PageTitle name="Page Not Found" />
        </div>
        <div sx={{mb: 5}}>
          <Headline>404</Headline>
          <ThicLine/>
        </div>      
        </Contain>
        
        <Contain mw={'col6'}></Contain>

        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}

        </Layout>
  )
}

export default NotFoundPage
